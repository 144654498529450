<template>
    <div class="l-padded">
        <div class="l-stack l-gap-2 dashboard-settings-view">
            <h3 class="t-title">
                {{ $t('title') }}
            </h3>

            <div class="l-stack l-gap-2">
                <BaseCard padded>
                    <p class="form-label">
                        {{ $t('selectUser') }}
                    </p>

                    <UserSelect
                        v-model="selectedUser"
                        :exclude="excludedUsers"
                        show-details
                    />
                </BaseCard>

                <BaseButton
                    :is-loading="isDataLoading"
                    :disabled="!selectedUser"
                    type="submit"
                    @click="handleSubmit"
                >
                    {{ $t('simulate') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import BaseButton from '../redesigned/BaseButton'
import BaseCard from '../redesigned/BaseCard'
import UserSelect from '../UserSelect'

export default {
    name: 'DashboardSettingsView',
    components: {
        BaseButton,
        BaseCard,
        UserSelect,
    },
    data() {
        return {
            selectedUser: null,
        }
    },
    computed: {
        ...mapState('auth', ['userInfo']),
        ...mapState('common', ['isDataLoading']),
        ...mapGetters('auth', ['getCurrentUserId']),
        excludedUsers() {
            return this.getCurrentUserId ? [this.getCurrentUserId] : undefined
        },
    },
    methods: {
        ...mapActions('auth', ['simulateUser']),
        ...mapActions('common', ['loadData']),
        ...mapMutations('common', ['setDataLoading']),
        async handleSubmit() {
            this.setDataLoading(true)
            await this.simulateUser(this.selectedUser)
            this.$root.$i18n.locale = this.userInfo.language
            this.$router.push('/', this.loadData)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "selectUser": "Select user to simulate:",
        "simulate": "Simulate",
        "title": "Settings"
    },
    "de": {
        "selectUser": "Benutzer für Simulation auswählen:",
        "simulate": "Simulieren",
        "title": "Einstellungen"
    },
    "fr": {
        "selectUser": "Selectionner l'utilisateur à simuler:",
        "simulate": "Simuler",
        "title": "Paramètres"
    },
    "it": {
        "selectUser": "Selezionare l'utente da simulare:",
        "simulate": "Simulare",
        "title": "Impostazioni"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-settings-view {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 600px;
}
</style>
